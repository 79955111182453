import React, { useState, useEffect } from 'react';
import Glitch from '~/components/B2BLanding/components/Glitchsection';
//import CompanyScroll from '~/presentational/B2BLanding/components/CompanyScroll';
import PageFooter from '~/components/B2BLanding/components/PageFooter';
import PageLayout from '~/components/B2BLanding/components/PageLayout';
import VerticalHeatwave from '~/components/B2BLanding/components/VerticalHeatwave';
import FAQ from '~/components/B2BLanding/sections/FAQ';
import Features from '~/components/B2BLanding/sections/Features';
import Footer from '~/components/B2BLanding/sections/Footer';
import Hero from '~/components/B2BLanding/sections/Hero';
import QuickFireFeature from '~/components/B2BLanding/sections/QuickFireFeatures';
import TheGames from '~/components/B2BLanding/sections/TheGames/TheGamesSection';
import usePreserveScroll from '~/components/B2BLanding/hooks/usePreserveScroll';

interface LandingPageProps {
  blogData: Array<{ title: string; slug: string }>;
}

const LandingPage: React.FC<LandingPageProps> = ({ blogData }) => {
  const [initialized, setInitialzied] = useState<boolean>(false);

  useEffect(() => {
    if (initialized) return;
    setInitialzied(true);
  }, [initialized]);

  useEffect(() => {
    if (window.location.hash) {
      const element = document.getElementById(
        window.location.hash.slice(1, window.location.hash.length)
      );
      element?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, []);

  const clearPreserveScroll = usePreserveScroll();

  useEffect(() => {
    clearPreserveScroll();
  }, [clearPreserveScroll]);

  return (
    <PageLayout blogData={blogData}>
      <VerticalHeatwave />
      <Hero noAnimation={true} />
      <QuickFireFeature noAnimation={true} />
      <Features />
      <Glitch />
      <div className='bg-night7'>
        <TheGames />
      </div>
      <div className='bg-night7'>
        <FAQ />
        <Footer />
      </div>
      <PageFooter />
    </PageLayout>
  );
};
export default LandingPage;
